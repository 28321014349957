<template>
  <div class="form-line" :class="{'-top': $attrs.value || inputFocus || autofill, '-error': validations.find( o => o.condition )}">
    <label :for="id">{{ placeholder }}</label>
    <select
      :id="id"
      :name="name"
      :autocomplete="autocomplete"
      @focus="focus()"
      @blur="blur()"
      :required="required ? true : false"
      :aria-required="required ? true : false"
      :aria-invalid="validations.find( o => o.condition ) ? true : false"
      :aria-describedby="validations.find( o => o.condition ) ? 'error-' + id : false"
      @input="$emit('input', $event.target.value)"
      @change="$emit('input', $event.target.value)"
    >
      <option disabled selected value v-if="!selected" />
      <option
        v-for="(option, key) in options"
        :key="key"
        :value="option.value"
        :disabled="option.disabled"
        v-bind="{selected: option.value === selected}"
      >
        {{ option.label }}
      </option>
    </select>
    <div class="input" v-text="input"></div>
    <ValidationMessages v-if="validations" :validations="validations" :id="id" />
  </div>
</template>

<script>
import ValidationMessages from './ValidationMessages.vue'

export default {
  name: 'BaseSelect',
  components: {
    ValidationMessages
  },
  data() {
    return {
      autofill: false,
      inputFocus: false
    }
  },
  computed: {
    input() {
      let option = this.options.find(o => (o.value.toString() === this.$attrs.value))
      return option ? option.label : null
    }
  },
  methods: {
    focus() {
      this.inputFocus = true
      this.$emit('focus')
    },
    blur() {
      this.inputFocus = false
      this.$emit('blur')
    }
  },
  props: {
    id: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    options: {
      type: Array,
      required: true,
      default: () => []
    },
    selected: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    autocomplete: {
      type: String,
      required: false,
      default: ''
    },
    validations: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    try {
      if (this.$el.querySelector('select:-webkit-autofill')) {
        this.autofill = true
      }
    } catch (e) {}
  }
}
</script>
