<template>
  <!-- On/Off like to accept terms of use -->
  <div class="toggle">
    <input type="checkbox" :checked="value" @change="$emit('input', $event.target.checked)" :id="id" :required="required ? true : false" :aria-required="required ? true : false">
    <div class="switch" :class="{ '-active': value }" @click="$emit('input', !value)">
      <span :class="{ '-active': value }">{{ onLabel }}</span>
      <span :class="{ '-active': !value }">{{ offLabel }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    id: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    },
    onLabel: {
      type: String,
      default: 'On'
    },
    offLabel: {
      type: String,
      default: 'Off'
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/core";

.toggle {
  position: relative;
  display: inline-block;

  .switch {
    z-index: 10;
    position: relative;
    height: 2.6rem;
    width: 6.5rem;
    padding: 0;
    border: none;
    border-radius: 1.3rem;
    background: var(--zenith-component-toggle-background);
    color: var(--zenith-component-toggle-text);
    text-align: center;
    cursor: pointer;

    &:before {
      display: block;
      content: '';
      position: absolute;
      top: .1rem;
      left: .1rem;
      width: 2.4rem;
      height: 2.4rem;
      background: var(--zenith-component-toggle-button-background);
      border-radius: 100%;
      transition: all 0.2s ease-in-out;
    }

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: -.2rem;
      right: -.2rem;
      bottom: -.2rem;
      left: -.2rem;
      opacity: 0;
      border: 1px solid var(--zenith-component-toggle-button-background);
      border-radius: 1.5rem;
      transition: all 0.2s ease-in-out;
    }

    span {
      opacity: 0;
      padding: 0 .4rem;
      line-height: 2.6rem;
      display: inline-block;
      font-weight: 600;
      font-size: em(12);
      letter-spacing: em(.2, 12);
      transition: all 0.2s ease-in-out;

      &.-active {
        opacity: 1;
      }
    }

    &.-active {
      color: var(--zenith-component-toggle-text-active);
      &:before {
        left: calc(100% - 2.5rem);
        background: var(--zenith-black);
      }

      &:after {
        border-color: var(--zenith-black);
      }
    }
  }

  input {
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    width: 1.2rem;
    height: 1.2rem;
    padding: 0;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-in-out;

    &:focus {
      & + .switch:after {
        opacity: 1;
      }
    }
  }

}

</style>
