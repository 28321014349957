<template>
  <modal
    id="generic-modal"
    :is-open="isOpen && settings && isPageAllowed()"
    @close="close"
  >
    <transition name="fade" mode="out-in">
      <div
        key="form"
        class="text-center"
        v-if="settings"
        :style="{
          color: settings.textsColor
        }"
      >
        <div class="row justify-content-center">
          <div class="modal-heading col-12">
            <h3
              class="modal-heading__headline"
              v-if="settings.headline"
              v-text="settings.headline"
            ></h3>
            <h2
              class="modal-heading__title"
              v-if="settings.title"
              v-html="settings.title"
            ></h2>
          </div>
        </div>
        <div class="row row-wrapper">
          <div class="col-6 d-none d-md-block image-col" v-if="settings.image">
            <div class="image-container">
              <lazy-image
                class="image-container__image"
                :item="settings.image"
                sizes="30w"
                ref="picture"
                rootMargin="0px"
              ></lazy-image>
            </div>
          </div>
          <form
            class="form col-12 col-md-6 offset-md-6 form-col"
            @submit.prevent="submit"
            novalidate
          >
            <div
              class="form-heading"
              :class="{
                'text-left': settings.textAlignment === 'left',
                'text-center': settings.textAlignment === 'center',
                'text-right': settings.textAlignment === 'right'
              }"
              v-if="settings"
              v-text="settings.text"
            ></div>
            <base-input
              id="email"
              type="email"
              name="email"
              autocomplete="email"
              v-model="email"
              @blur="$v.email.$touch()"
              :placeholder="$t('E-mail address') + ' *'"
              :validations="[
                {
                  condition: !$v.email.required && $v.email.$error,
                  text: $t('Field is required.')
                },
                {
                  condition: !$v.email.email && $v.email.$error,
                  text: $t('Please provide valid e-mail address.')
                }
              ]"
              :required="true"
            />
            <base-select
              id="title"
              name="title"
              v-model="title"
              autocomplete="honorific-prefix"
              :placeholder="$t('Title') + ' *'"
              :validations="[
                {
                  condition: $v.title.$error && !$v.title.required,
                  text: $t('Field is required.')
                }
              ]"
              :required="true"
              :options="[
                { value: 'mr', label: $t('Mr.') },
                { value: 'mrs', label: $t('Mrs.') }
              ]"
            />
            <base-input
              id="name"
              type="text"
              name="name"
              autocomplete="given-name"
              v-model="name"
              @blur="$v.name.$touch()"
              :placeholder="$t('First name') + ' *'"
              :validations="[
                {
                  condition: !$v.name.required && $v.name.$error,
                  text: $t('Field is required.')
                }
              ]"
              :required="true"
            />
            <base-input
              type="text"
              id="surname"
              name="surname"
              autocomplete="family-name"
              v-model="surname"
              @blur="$v.surname.$touch()"
              :placeholder="$t('Last name') + ' *'"
              :validations="[
                {
                  condition: !$v.surname.required && $v.surname.$error,
                  text: $t('Field is required.')
                }
              ]"
              :required="true"
            />
            <div
              class="toggle-line"
              :class="{
                '-error': !$v.conditions.required && $v.conditions.$error
              }"
            >
              <Toggle
                v-model="conditions"
                id="conditions"
                :required="true"
              ></Toggle>
              <NoSSR>
                <label
                  class="-small"
                  for="conditions"
                  v-html="
                    $t('I accept the terms of use', {
                      href: localizedUrl + '/page/terms'
                    })
                  "
                  >*</label
                >
              </NoSSR>
              <div class="form-error">
                <label for="conditions">{{ $t('Field is required.') }}</label>
              </div>
            </div>
            <div class="toggle-line">
              <Toggle v-model="is_subscribed" id="is_subscribed"></Toggle>
              <label class="-small" for="is_subscribed">{{
                $t('I would like to sign up for the newsletter')
              }}</label>
            </div>
            <div
              class="recaptcha"
              v-html="
                $t(
                  'This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.',
                  {
                    hrefPrivacy: 'https://policies.google.com/privacy',
                    hrefTerms: 'https://policies.google.com/terms'
                  }
                )
              "
            ></div>
            <CustomLoader :is-loading="loading">
              <button
                class="btn"
                type="submit"
                v-if="settings.ctaLabel"
                :style="buttonStyle"
                :class="{
                  '-custom-bg-color': settings.textsColor !== this.DEFAULT_TEXT_COLOR
                }"
                v-text="settings.ctaLabel"
              ></button>
              <button class="btn" type="submit" v-else>
                {{ $t('Send') }}
              </button>
            </CustomLoader>
          </form>
        </div>
      </div>
    </transition>
  </modal>
</template>

<script>
import { email, required, sameAs } from 'vuelidate/lib/validators'
import { ReadyHook } from 'vue-asyncready'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { mapActions, mapGetters } from 'vuex'
import { PAGES, UIDS } from 'src/modules/prismic/content-types'
import Modal from '../utils/Modal'
import { newsletter } from '../../utilities'
import Toggle from '../form/Toggle'
import BaseInput from '../form/BaseInput'
import BaseSelect from '../form/BaseSelect'
import BaseTextarea from '../form/BaseTextarea'
import NoSSR from 'src/themes/zenith/node_modules/vue-no-ssr'
import LazyImage from '../utils/LazyImage'
import ImageManager from 'vue-storefront-prismic-ssr/utilities/ImageManager'
import config from 'config'
import { isServer } from '@vue-storefront/core/helpers'
import CustomLoader from '../utils/CustomLoader.vue'

const type = PAGES.POPUP
const uid = UIDS.POPUP
const COOKIE_CLOSED_VALUE = 'closed'

export default {
  props: [],
  name: 'PopupBlock',
  components: {
    Modal,
    BaseInput,
    BaseSelect,
    BaseTextarea,
    Toggle,
    LazyImage,
    NoSSR,
    Toggle,
    CustomLoader
  },
  mixins: [ReadyHook],
  data() {
    return {
      loading: false,
      settings: null,
      success: false,
      title: '',
      name: '',
      surname: '',
      email: '',
      is_subscribed: false,
      isOpen: false,
      contactSubject: '',
      conditions: false,
      DEFAULT_TEXT_COLOR: 'black'
    }
  },
  serverPrefetch() {
    this.retrievePopupData()
  },
  created() {
    this.retrievePopupData()

    // check if there is a cookie not expired to set isOpen to true
    if (!isServer) {
      if (this.$cookies.get('popup') !== COOKIE_CLOSED_VALUE) {
        this.isOpen = true
      }
    }
  },
  mounted() {
    this.$v.$reset()
    if (this.currentUser) {
      this.title = this.currentUser.prefix || ''
      this.name = this.currentUser.lastname || ''
      this.surname = this.currentUser.firstname || ''
      this.email = this.currentUser.email || ''
      this.is_subscribed =
        this.currentUser.extension_attributes.is_subscribed || ''
    }
  },
  computed: {
    ...mapGetters('prismic', ['getPage']),
    countryCode() {
      const { i18n } = currentStoreView()
      return i18n ? i18n.defaultCountry : 'XX'
    },
    localizedUrl() {
      return currentStoreView().url || ''
    },
    currentUser() {
      return this.isLoggedIn ? this.$store.state.user.current : null
    },
    currentView() {
      const { storeCode, i18n } = currentStoreView()
      const { fullCountryName, fullLanguageName } = i18n
      return {
        lang: fullLanguageName,
        country: fullCountryName,
        storeCode
      }
    },
    buttonStyle() {
      return {
        backgroundColor: this.settings.textsColor
      }
    }
  },
  methods: {
    ...mapActions('feedback', ['show']),
    ...mapActions('prismic', ['setPage']),
    ...mapGetters('user', ['isLoggedIn']),
    async retrievePopupData() {
      await this.setPage({ type, uid }).then(() => {
        const page = this.getPage({ type, uid })

        if (page) {
          this.settings = this.createData(page)
          this.contactSubject = page.main ? page.main.contact_subject : ''
        }
      })
    },
    createData(page) {
      const pictureUrl =
        page.main?.image && page.main?.image?.url
          ? ImageManager.convert(page.main.image.url)
          : null
      return {
        image: pictureUrl
          ? {
              url: pictureUrl,
              lowDefinitionUrl: ImageManager.getLowDefinition(
                pictureUrl,
                200,
                1.48
              ),
              srcset: ImageManager.getSrcSet(pictureUrl, 1244, 311, 100, 1.48),
              alt: page.main.image.alt,
              width: page.main.image.width,
              height: page.main.image.height
            }
          : null,
        headline: page.main.headline || null,
        title: page.main.title || null,
        text: page.main.text || null,
        textAlignment: page.main.text_alignment || null,
        textsColor: page.main.texts_color || this.DEFAULT_TEXT_COLOR,
        ctaLink: page.main.cta_link || null,
        ctaLabel: page.main.cta_label || null,
        pagesDisallowed:
          page.content && page.content.length > 0
            ? page.content
                .filter(item => item.component === 'pages_disallowed')[0]
                ?.items.map(item => item.page.toLowerCase())
            : null
      }
    },
    async submit() {
      const formData = {
        civility: this.title,
        lastname: this.surname,
        firstname: this.name,
        country_id: this.countryCode,
        subject: this.contactSubject,
        email: this.email,
        message: '',
        locale: currentStoreView().storeCode
      }
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        await this.$store.dispatch('googleRecaptcha/isVerifiedCaptcha')
        if (!this.$store.state.googleRecaptcha.is_verified) {
          this.show({
            message: 'Recaptcha error. Please try again.',
            context: 'error'
          })
        } else {
          this.loading = true
          TaskQueue.execute({
            url: config.api.url + '/api/ext/contact/send',
            payload: {
              method: 'POST',
              mode: 'cors',
              headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(formData)
            }
          })
            .then(response => {
              if (response.code === 200) {
                if (this.is_subscribed) {
                  return newsletter({
                    email: this.email,
                    civility: this.title,
                    firstname: this.name,
                    lastname: this.surname,
                    country: this.countryCode
                  })
                    .then(() => {
                      this.$gtm.trackEvent({
                        event: 'newsletterSubscription',
                        siteLocation: 'Newsletter page'
                      })
                      this.close()
                    })
                    .catch(error => {
                      this.show({
                        message: 'Newsletter registration failed',
                        context: 'error'
                      })
                      console.log('Newsletter registration failed', error)
                    })
                } else {
                  this.close()
                }
              }

              return null
            })
            .catch(error => {
              this.show({
                message: 'Notification registration failed',
                context: 'error'
              })
              console.log('Contact failed', error)
              this.loading = false
            })
        }
      }
    },
    close() {
      // set cookie of 1 day
      this.$cookies.set('popup', 'closed', {
        path: '/',
        expires: new Date(Date.now() + 86400000)
      })

      this.isOpen = false
    },
    isPageAllowed() {
      const pageType =
        (this.$route.meta && this.$route.meta.pageType).toLowerCase() || null
      return !this.settings.pagesDisallowed.includes(pageType)
    }
  },
  validations: {
    title: {
      required
    },
    name: {
      required
    },
    surname: {
      required
    },
    email: {
      required,
      email
    },
    conditions: {
      sameAs: sameAs(() => true)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

.modal {
  .fade-enter,
  .fade-leave-to {
    position: static;
  }
}

.modal-cpt /deep/ .content {
  max-width: 90rem;
  padding: 4rem 2rem;

  @include bp(md) {
    max-width: 90%;
    max-height: 90%;
    width: 100%;
  }

  @include bp(xl) {
    max-width: 120rem;
    max-height: 90%;
  }

  @include bp(xxl) {
    max-width: 90rem;
    max-height: 80%;
  }
}

.modal .content .row {
  margin: 0 -1rem !important;
}
.modal .content .title {
  margin-bottom: 2rem;
}

.form {
  .form-heading {
    font-size: em(18);
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 1rem;
  }
  .toggle-line {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: left;

    label.-small a {
      color: inherit;
    }
  }
  p {
    margin-bottom: 2rem;
  }

  /deep/ .form-line {
    text-align: left;

    label,
    &.-top > label:not(.btn) {
      left: 0;
    }

    input,
    .input {
      padding-left: 0;
    }
  }
}

.recaptcha {
  font-size: em(12);
  margin-bottom: 2rem;
  /deep/ a {
    text-decoration: underline;
  }
}

.modal-heading {
  margin-bottom: 1.5rem;
  &__headline {
    font-size: em(14);
    margin-bottom: 0;
  }

  &__title {
    font-size: em(28);
    margin-bottom: 0;
  }
}

.image-container {
  position: relative;
  overflow: hidden;
  height: 0;
  padding: 0;
  transition: $transition-default-fast;
  background: none;
  outline: none;
  height: 100%;
  overflow: hidden;

  &__image {
    height: 100%;
    overflow: hidden;

    /deep/ img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.btn.-custom-bg-color {
  &::after {
    content: unset;
  }

  &:hover,
  &:active,
  &:focus {
    opacity: 0.8;
    color: white;
  }
}

.row-wrapper {
  position: relative;
}

.image-col {
  position: absolute;
  height: 100%;
  overflow: hidden;
}
</style>
