<template>
  <ul class="skipLinks">
    <li>
      <router-link :to="`${localizedUrl}`" :title="$t('Home page')" accesskey="0">{{ $t('Home page') }}</router-link>
    </li>
    <li>
      <a href="#navigation" :title="$t('Navigation')" accesskey="1">{{ $t('Navigation') }}</a>
    </li>
    <li>
      <a href="#main" :title="$t('Content')" accesskey="2">{{ $t('Content') }}</a>
    </li>
    <li v-if="isContactEnabled">
      <router-link :to="`${localizedUrl}/contact`" :title="$t('Contact')" accesskey="3">{{ $t('Contact') }}</router-link>
    </li>
    <li>
      <router-link :to="`${localizedUrl}/search`" :title="$t('Search')" accesskey="4">{{ $t('Search') }}</router-link>
    </li>
  </ul>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  name: 'SkipLinks',
  mixins: [ReadyHook],
  computed: {
    localizedUrl() {
      return currentStoreView().url || ''
    },
    isContactEnabled() {
      const { contact } = currentStoreView()
      return contact !== false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/core";

.skipLinks {
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 200;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    a {
      display: block;
      position: absolute;
      z-index: 200;
      top: 0;
      left: 0;
      padding: 1rem 2rem;
      font-size: em(16);
      white-space: nowrap;
      color: var(--zenith-white);
      background: var(--zenith-black);
      opacity: 0;
      transform: translate(-200%, -200%);
      transition: opacity 0.2s ease-in-out, transform 0s 0.2s;

      &:focus {
        opacity: 1;
        transform: translate(0, 0);
        transition: opacity 0.2s ease-in-out;
      }
    }
  }
}
</style>
