<template>
  <div :class="this.CssClasses">
    <div>
      <h3 class="ab--contract__header">{{ $t('need help?') }}</h3>
    </div>
    <div class="ab--contract__content">
      <div class="ab--contract__content__phone" :style="{justifyContent: this.textalign || 'flex-start'}">
        <i class="icon-tel" :aria-hidden="true"></i>
        <div><span>{{$t('Call us at:')}}</span
            >
            <a :href="'tel:' + $t('phone')" class="custom-link-light">{{$t('phone')}}</a></div>
      </div>
      <div class="ab--contract__content__email" :style="{justifyContent: this.textalign || 'flex-start'}">
        <i class="icon-mail" :aria-hidden="true"></i>
        <div>
            <span>{{ $t('Contact us by') }}</span>
        <router-link
          :to="localizedUrl + '/contact'"
          class="custom-link-light"
          >{{$t('Email')}}</router-link
        >
        </div>
        
      </div>
      <div class="ab--contract__content__faq" :style="{justifyContent: this.textalign || 'flex-start'}">
        <i class="icon-info" :aria-hidden="true"></i>
        <div>
            <span>{{$t('See our')}}</span>
        <router-link :to="localizedUrl + '/FAQ'" class="custom-link-light">{{$t('FAQ')}}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { currentStoreView } from '@vue-storefront/core/lib/multistore'
export default {
  name: 'CountactInfoSection',
  props: ['textalign', 'classes'],
  computed: {
    localizedUrl () {
      return currentStoreView().url || ''
    },
    CssClasses() {
      return ['ab--contract', this.classes]
    }
  }
}
</script>

<style lang="scss" scoped>
@import'~theme/css/core';
.ab--contract {
  width: 100%;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: $light-grey;
  text-align: left;
  &__header {
    margin: unset;
    font-family: Prompt, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 149%;
    letter-spacing: 0.16em;
    color: #002649;
    text-transform: uppercase;
  }
  &__content {
    padding: 0 10px 0 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: #002649;
    & > div {
      display: flex;
      gap: 15px;
      align-items: center;
      font-size: 12px;
    }
    svg {
      min-height: 16px;
      height: 16px;
      min-width: 16px;
      width: 16px;
      fill: currentColor;
    }
    .custom-link-light {
      position: relative;
      display: inline-block;
      height: 14px;
      font-family: Prompt, Arial, sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 1.1;
      letter-spacing: 0.025em;
      text-transform: unset;
      color: #002649;
      text-decoration: underline !important;
      margin-right: unset;
      padding-bottom: 1px;
    }
  }
  @media (min-width: 770px) {
    &__header {
        text-align: center;
    }
    }
}
.contact-footer {
    text-align: center;
    .ab--contract {
        &__content > div {
            gap: 8px;
            flex-direction: column;
    }
    }
  }
</style>
