<template>
  <div class="form-line" :class="{'-top': value || inputFocus || autofill, '-error': validations.find( o => o.condition )}">
    <label :for="id">{{ placeholder }}</label>
    <textarea
      :id="id"
      :name="name"
      :autocomplete="autocomplete"
      :value="value"
      :autofocus="autofocus"
      :ref="focus ? name : false"
      @input="$emit('input', $event.target.value)"
      @blur="blur()"
      @focus="inputFocus = true"
      :required="required ? true : false"
      :aria-required="required ? true : false"
      :aria-invalid="validations.find( o => o.condition ) ? true : false"
      :aria-describedby="validations.find( o => o.condition ) ? 'error-' + id : false"
      @keyup.enter="$emit('keyup.enter', $event.target.value)"
      @keyup="$emit('keyup', $event)"
    />
    <ValidationMessages v-if="validations" :validations="validations" :id="id" />
  </div>
</template>

<script>
import ValidationMessages from './ValidationMessages.vue'

export default {
  name: 'BaseTextarea',
  components: {
    ValidationMessages
  },
  data () {
    return {
      inputFocus: false,
      iconActive: false,
      icon: 'visibility',
      autofill: false
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    autocomplete: {
      type: String,
      required: false,
      default: ''
    },
    focus: {
      type: Boolean,
      required: false,
      default: false
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    validations: {
      type: Array,
      default: () => []
    }
  },
  mounted () {
    if (this.focus) {
      this.$refs[this.name].focus()
    }
    try {
      if (this.$el.querySelector('textarea:-webkit-autofill')) {
        this.autofill = true
      }
    } catch (e) {}
  },
  methods: {
    blur () {
      this.inputFocus = false
      this.$emit('blur')
    }
  }
}
</script>
