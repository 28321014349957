<template>
  <div class="d-flex justify-content-center">
    <div v-if="isLoading" class="loader" :class="color"></div>
    <slot v-else></slot>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  name: 'CustomLoader'
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';
.loader {
  position: relative;
  width: 2rem;
  height: 2rem;
  margin-top: -2rem;
  border-radius: 100%;
  transform: translateZ(0);
  animation-fill-mode: both;
  animation: scaleIn 1.8s infinite ease-in-out;
  animation-delay: -0.16s;

  &.-white {
    color: white;
  }
  &.-black {
    color: black;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    animation-fill-mode: both;
    animation: scaleIn 1.8s infinite ease-in-out;
  }
  &:before {
    left: -3.5rem;
    animation-delay: -0.32s;
  }
  &:after {
    left: 3.5rem;
  }
}
@keyframes scaleIn {
  0%,
  80%,
  100% {
    box-shadow: 0 2.2rem 0 -1rem;
  }
  40% {
    box-shadow: 0 2.2rem 0 -0.1rem;
  }
}
</style>
