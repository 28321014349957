<template>
  <button type="button" @click="scrollTop" :aria-label="$t('Scroll to top')">
    <svg width="48" height="48">
      <circle ref="circle" cx="24" cy="24" r="23" stroke="#000" stroke-width="1" fill="#FFF" />
    </svg>
    <span>★</span>
  </button>
</template>

<script>
import updateOnScroll from 'uos'
import { ReadyHook } from 'vue-asyncready'

export default {
  name: 'Progression',
  mixins: [ReadyHook],
  methods: {
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  },
  mounted () {
    updateOnScroll(0, 1, progress => {
      if (this.$refs.circle) this.$refs.circle.style.strokeDashoffset = (14.5 - 14.5 * progress) + 'rem'
    })
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/core";

button {
  position: fixed;
  z-index: 100;
  bottom: 3.6rem;
  right: 3.6rem;
  width: 4.8rem;
  height: 4.8rem;
  padding: 0;
  text-align: center;
  background: var(--zenith-pill-background);
  border-radius: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 0 1px 1px var(--zenith-pill-shadow);

  @include bp(max md) {
    display: none;
  }

  span {
    position: absolute;
    display: block;
    z-index: 10;
    top: 50%;
    left: 50%;
    color: var(--zenith-pill-star-color);
    line-height: 4.8rem;
    font-size: em(24);
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-in-out;
  }

  svg {
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    circle {
      stroke-dasharray: 14.5rem;
      stroke-dashoffset: 14.5rem;
      will-change: stroke-dashoffset;
      transition: stroke-dashoffset 0.1s linear;
    }
  }

  &:hover, &:focus, &:active {
    outline: none;
    background: var(--zenith-pill-background-hover);
    span {
      color: var(--zenith-pill-star-color-hover)
    }
    svg circle {
      fill: var(--zenith-pill-circle-fill-hover);
      stroke: var(--zenith-pill-circle-background-hover);
    }
  }
}
</style>
