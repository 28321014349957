<template>
  <div>
    <SpeedTransitionPage />
    <SkipLinks></SkipLinks>

    <Header v-if="$route.name !== 'page-not-found'" />

    <main ref="main" class="main">
      <slot />
    </main>
    <Footer v-if="$route.name !== 'page-not-found'" />
    <PopupBlock />
    <Progression v-if="$route.name !== 'page-not-found'" />
    <vue-progress-bar v-if="$route.name !== 'page-not-found'" />
    <offline-badge v-if="OfflineOnly" />
  </div>
</template>

<script>
import { isServer } from '@vue-storefront/core/helpers'
import Head from 'theme/head'
import config from 'config'
import NoSSR from 'src/themes/zenith/node_modules/vue-no-ssr'
import Header from 'src/themes/zenith/components/Header'
import Footer from 'src/themes/zenith/components/Footer'
import SkipLinks from 'src/themes/zenith/components/SkipLinks'
import TopMessage from 'src/themes/zenith/components/TopMessage'
import Progression from 'src/themes/zenith/components/utils/Progression'
// import OfflineBadge from 'src/themes/zenith/components/utils/OfflineBadge'
// import MainTransitionPage from 'src/themes/zenith/components/utils/MainTransitionPage'
// import LightTransitionPage from 'src/themes/zenith/components/utils/LightTransitionPage'
import SpeedTransitionPage from 'src/themes/zenith/components/utils/SpeedTransitionPage'
import { mapActions, mapGetters } from 'vuex'
import { Logger } from '@vue-storefront/core/lib/logger'
import VueOfflineMixin from 'vue-offline/mixin'
import {
  currentStoreView,
  localizedRoute
} from '@vue-storefront/core/lib/multistore'
import { getRouteName } from '../utilities'
import { GlobalSettings } from 'src/modules/prismic/components/GlobalSettings'
import FlashingTab from 'src/modules/zenith-ui/components/FlashingTab'
import PopupBlock from '../components/cms/PopupBlock.vue'

export default {
  mixins: [VueOfflineMixin, GlobalSettings, FlashingTab],
  components: {
    NoSSR,
    Header,
    Footer,
    PopupBlock,
    // Burger: () => import(/*  webpackChunkName: "burger-default-cpt" */'./../components/Burger'),
    SkipLinks,
    TopMessage,
    // TopMessage: () => import(/* webpackChunkName: "top-message-default-cpt" */ /* webpackPreload: true */ './../components/TopMessage'),
    // Progression: () => import(/* webpackChunkName: "progression-default-cpt" */'./../components/utils/Progression'),
    Progression,
    // MainTransitionPage,
    // LightTransitionPage,
    SpeedTransitionPage,
    OfflineBadge: () =>
      import(
        /* webpackChunkName: "offline-badge-default-cpt" */ './../components/utils/OfflineBadge'
      )
  },
  watch: {
    isLoggedIn() {
      Logger.info(
        '[Default] [User login] User loggedin status changed ',
        this.currentUser
      )()
      if (!this.currentUser && this.$route.path.includes('my-profile')) {
        this.$router.push(localizedRoute('/login'))
      }
    }
  },
  computed: {
    ...mapGetters('user', ['isLoggedIn']),
    currentUser() {
      if (this.isLoggedIn) return this.$store.state.user.current
      else return null
    },
    isMobile() {
      return this.$store.state['zenith-ui'].menu.mobile
    },
    route() {
      return getRouteName(this.$route.name)
    }
  },
  methods: {
    ...mapActions('wishlist-extend', ['clear', 'loadWishlist']),
    fetchMenuData() {
      return this.$store.dispatch('category/list', {
        level:
          config.entities.category.categoriesDynamicPrefetch &&
          config.entities.category.categoriesDynamicPrefetchLevel >= 0
            ? config.entities.category.categoriesDynamicPrefetchLevel
            : null,
        includeFields:
          config.entities.optimize && isServer
            ? config.entities.category.includeFields
            : null,
        skipCache: isServer
      })
    },
    onUserLogout() {
      Logger.info('[Default] User logged out')()
      this.clear()
    },
    onUserLogin() {
      localStorage.removeItem('shop/carts/current-cart')
      Logger.info('[Default] User logged in')()
      this.loadWishlist()
    },
    onSessionStarted() {
      Logger.info('[Default] User session started')()
    },
    checkUserStatus() {
      if (this.currentUser) {
        Logger.info(
          '[Default] checkUserStatus : user found ',
          this.currentUser
        )()
        this.loadWishlist()
      } else {
        Logger.info(
          '[Default] checkUserStatus : user not found ',
          this.currentUser
        )()
      }
    }
  },
  serverPrefetch() {
    return this.fetchMenuData()
  },
  beforeMount() {
    this.$bus.$on('user-after-logout', this.onUserLogout)
    this.$bus.$on('user-after-loggedin', this.onUserLogin)
    this.$bus.$on('session-after-started', this.onSessionStarted)
    this.checkUserStatus()
    // Progress bar on top of the page
    this.$router.beforeEach((to, from, next) => {
      this.$Progress.start()
      this.$Progress.increase(40)
      next()
    })
    this.$router.afterEach((to, from) => {
      this.$store.dispatch('zenith-ui/resetMenu')
      this.$Progress.finish()
    })
  },
  metaInfo: Head
}
</script>

<style lang="scss">
@import '~theme/css/main';
@import '~theme/css/core';
@import '~theme/css/hooper';
@import '~theme/css/keyframes';

main {
  min-height: 100vh;
  flex-grow: 1;
  position: relative;
  margin-bottom: 0;

  &.-product {
    padding-bottom: 12rem;

    @include bp(max md) {
      padding-bottom: 9rem;
    }
  }
}

.header-wrapper.-transparent + main {
  margin-top: -12rem;
  margin-bottom: 0;

  @include bp(max md) {
    margin-top: -9rem;
    margin-bottom: 0;
  }
}
header.-global.-transparent + main {
  @include bp(max md) {
    margin-top: -9rem;
  }
}
:root {
  --color-mode: 'light';
  @include lightMode();
}
//@media (prefers-color-scheme: dark) {
//  :root {
//    --color-mode: 'dark';
//  }
//
//  :root:not([data-user-color-scheme]) {
//    @include darkMode();
//  }
//}
//[data-user-color-scheme='dark'] {
//  @include darkMode();
//}
</style>
