<template>
  <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.9 19.82">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M3.05,10.85l2.3-3.67-.9-5.13H.5S1,18.83,17.84,19.32V15.43l-5-.83L9.18,17"
        ></path>
        <path class="cls-2" d="M9.71,5.3a5.5,5.5,0,0,1,4.63,4.64"></path>
        <path class="cls-2" d="M11,2.85a7,7,0,0,1,5.78,5.8"></path>
        <path class="cls-2" d="M12.35.4a8,8,0,0,1,7.15,7"></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ContactPhoneIcon'
}
</script>

<style lang="scss" scoped>
.icon {
  position: absolute;
  height: 16px;
  width: 16px;
}

.cls-1,
.cls-2 {
  fill: none;
  stroke: var(--zenith-header-text-link);
  stroke-linecap: round;
  stroke-linejoin: round;
}
.cls-2 {
  stroke-width: 0.8px;
}
</style>
