<template>
  <transition name="fade">
    <div :id="'modal-' + id" class="modal modal-cpt" :class="fullWidth ? '-full-width' : ''" @click.self="$emit('close')" v-if="isOpen">
      <div class="content" v-bsl="isOpen">
        <button type="button" ref="close" @click="$emit('close')" :aria-label="$t('Close')"><i class="icon-close"></i></button>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'

export default {
  props: ['isOpen', 'fullWidth', 'id'],
  name: 'Modal',
  mixins: [ReadyHook],
  watch: {
    isOpen (newVal) {
      if (newVal) {
        setTimeout(() => {
          this.$refs.close.focus()
        }, 100)
        document.body.style.overflowY = 'hidden'
      } else {
        document.body.style.overflowY = 'auto'
      }
    }
  },
  methods: {
    keyup(e) {
      if (e.keyCode === 27) {
        this.$emit('close')
      }
    },
    trapFocus(e) {
      if (this.isOpen && !(this.$el === e.target || this.$el.contains(e.target)) && (!e.target.classList || !e.target.classList.contains('modal'))) {
        this.$refs.close.focus()
      }
    }
  },
  mounted() {
    document.addEventListener('keyup', this.keyup)
    document.addEventListener('focus', this.trapFocus, true)
  },
  destroyed() {
    document.removeEventListener('keyup', this.keyup)
    document.removeEventListener('focus', this.trapFocus, true)
  }
}
</script>
