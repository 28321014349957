<template>
  <div>
    <NoSSR>
      <div class="login-popup" v-if="this.isNewUser && !menuOpen">
        <button class="close-btn" @click="this.close">
          <i class="icon-close"></i>
        </button>
        <p
          class="title"
          v-html="$t('Zenith watches now come with <b>5 year warranty</b>')"
        ></p>
        <button
          :style="{ color: 'white', background: 'black' }"
          @click="this.anchorClick"
          class="btn -block"
          v-text="$t('SIGN-UP NOW')"
        ></button>
        <p>
          <i>{{
            $t('Register your watch to get the extra 3 year warranty')
          }}</i>
        </p>
      </div>
    </NoSSR>
  </div>
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import NoSSR from 'src/themes/zenith/node_modules/vue-no-ssr'
import Vue from 'vue'
import { isServer } from '@vue-storefront/core/helpers'
const HIDE_AFTER_TOT_DAYS = 1
const SEC_PER_DAY = 1000 * 60 * 60 * 24

export default {
  name: 'LoginTooltip',
  props: ['menuOpen'],
  components: {
    NoSSR
  },
  data() {
    return {
      isNewUser: false
    }
  },
  created() {
    if (!isServer) {
      const lastViewed = Vue.prototype.$cookies.get(`Zmlyc3RfdmlzaXQ=`)
      if (lastViewed) {
        this.isNewUser =
          Date.now() < Number(lastViewed) + HIDE_AFTER_TOT_DAYS * SEC_PER_DAY
      } else {
        Vue.prototype.$cookies.set(`Zmlyc3RfdmlzaXQ=`, Date.now())
        this.isNewUser = true
      }
    }
  },
  methods: {
    close() {
      this.isNewUser = false
      Vue.prototype.$cookies.set(`Zmlyc3RfdmlzaXQ=2`, 1)
      Vue.prototype.$cookies.set(`Zmlyc3RfdmlzaXQ=`, 1)
    },
    anchorClick() {
      this.close()
      this.$router.push(this.localizedRoute('/login'))
    }
  },
  computed: {
    localizedUrl() {
      return currentStoreView().url || ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';
.login-popup {
  position: fixed;
  background-color: white;
  padding: 33px 40px 21px 40px;
  text-align: center;
  left: 0%;
  right: 0%;
  bottom: 0%;
  a {
    margin-bottom: 6px;
    filter: none !important;
    border-radius: 4px;
  }
  button {
    border-radius: 4px;
  }
  p {
    font-size: 1.1rem;
    line-height: 1.7rem;
    margin: 0%;
    &.title {
      text-transform: uppercase;
      margin-bottom: 4px;
    }
  }
  .close-btn {
    border: none;
    background: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0%;
    filter: none !important;
  }
  @include bp(md) {
    position: absolute;
    background-color: white;
    border: solid 1px;
    padding: 33px 10px 21px 10px;
    text-align: center;
    left: -7rem;
    bottom: unset;
    right: unset;
    min-width: 19rem;
    &::after {
      content: '';
      position: absolute;
      background-color: white;
      height: 15px;
      width: 15px;
      top: -8px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      border-top: solid 1px;
      border-left: solid 1px;
    }
    a {
      margin-bottom: 6px;
      filter: none !important;
      border-radius: 4px;
    }
    p {
      font-size: 1.1rem;
      line-height: 1.7rem;
      margin: 0%;
      &.title {
        text-transform: uppercase;
        margin-bottom: 4px;
      }
    }
    .close-btn {
      border: none;
      background: none;
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 0%;
      filter: none !important;
    }
  }
}
</style>
