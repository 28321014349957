import { mapGetters, mapActions } from 'vuex'
import { PAGES, UIDS } from '../content-types'

const type = PAGES.GLOBAL_SETTINGS
const UID = UIDS.GLOBAL_SETTINGS

export const GlobalSettings = {
  name: 'BrandComponent',
  computed: {
    ...mapGetters('prismic', ['getPage', 'getBlock'])
  },
  methods: {
    ...mapActions('prismic', ['setBlock', 'setPage']),
    setLogoImage() {
      if (
        this.page &&
        this.page.main &&
        this.page.main.logo &&
        this.page.main.logo.url
      ) {
        const logo = this.page.main.logo.url
        const hpWhiteLogo = this.page.main.hp_mobile_white_logo
        let invertLogoOnTransparentHeader = this.page.main.invert_logo_on_transparent_header

        if (invertLogoOnTransparentHeader === undefined) {
          invertLogoOnTransparentHeader = true
        }

        this.$store.commit('zenith-ui/setLogo', {
          url: logo,
          homepageWhiteLogoMobile: hpWhiteLogo,
          invertLogoOnTransparentHeader: invertLogoOnTransparentHeader
        })
      }
    }
  },
  async serverPrefetch() {
    await this.setPage({ type, uid: UID })

    this.page = this.getPage({ type, uid: UID })

    this.setLogoImage()
  },
  async created() {
    this.page = this.getPage({ type, uid: UID })
    if (!this.page) {
      await this.setPage({ type, uid: UID })
      this.page = this.getPage({ type, uid: UID })
    }

    this.setLogoImage()
  }
}
