import { mapGetters } from 'vuex'

const FlashingTab = {
  data() {
    return {
      pageTitle: ''
    }
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
  },
  methods: {
    handleVisibilityChange() {
      if (document.visibilityState === 'hidden') {
        this.pageTitle = document.title
        document.title = this.pageTitleOnBlur
      } else {
        document.title = this.pageTitle
      }
    }
  },
  computed: {
    ...mapGetters('cart', ['getCartItems']),
    pageTitleOnBlur() {
      let totalCartItems = 0
      if (this.getCartItems) {
        totalCartItems = this.getCartItems.length
      }

      if (totalCartItems > 0) {
        return this.$t('Do not miss it!')
      } else {
        return this.$t('Reach your star!')
      }
    }
  }
}

export default FlashingTab
