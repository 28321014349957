import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import config from 'config'
const lang = currentStoreView().i18n.defaultLanguage.toLowerCase()
export default {
  title: 'Zenith',
  titleTemplate: '%s - ZENITH',
  htmlAttrs: {
    lang
  },
  meta: [
    { charset: 'utf-8' },
    { vmid: 'description', name: 'description', content: 'Zenith watches' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { name: 'robots', content: 'index, follow' },
    { name: 'mobile-web-app-capable', content: 'yes' },
    { name: 'theme-color', content: '#ffffff' },
    { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' },
    { name: 'detectify-verification', content: 'adfd30d01e594ac3e98ef94e412f778b' },
    { name: 'google-site-verification', content: 'bOyICu3go_MboQIQVo7JGLpBTWNPRN7iwYKxw-Fjw58' }
  ],
  link: [
    { rel: 'icon', type: 'image/png', href: '/assets/favicon-32x32.png', sizes: '32x32' },
    { rel: 'icon', type: 'image/png', href: '/assets/favicon-16x16.png', sizes: '16x16' },
    { rel: 'apple-touch-icon', href: '/assets/icons/pwa/apple-touch-icon.png' },
    { rel: 'apple-touch-startup-image', href: '/assets/icons/pwa/apple_splash_2048.png', sizes: '2048x2732' },
    { rel: 'apple-touch-startup-image', href: '/assets/icons/pwa/apple_splash_1668.png', sizes: '1668x2224' },
    { rel: 'apple-touch-startup-image', href: '/assets/icons/pwa/apple_splash_1536.png', sizes: '1536x2048' },
    { rel: 'apple-touch-startup-image', href: '/assets/icons/pwa/apple_splash_1125.png', sizes: '1125x2436' },
    { rel: 'apple-touch-startup-image', href: '/assets/icons/pwa/apple_splash_1242.png', sizes: '1242x2208' },
    { rel: 'apple-touch-startup-image', href: '/assets/icons/pwa/apple_splash_750.png', sizes: '750x1334' },
    { rel: 'apple-touch-startup-image', href: '/assets/icons/pwa/apple_splash_640.png', sizes: '640x1136' },
    { rel: 'manifest', href: '/assets/manifest.json', crossorigin: 'use-credentials' },
    { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: undefined },
    { rel: 'preconnect', href: 'https://www.googletagmanager.com', crossorigin: undefined },
    { rel: 'preconnect', href: 'https://www.google-analytics.com', crossorigin: undefined },
    { rel: 'preconnect', href: 'https://www.google.com', crossorigin: undefined },
    { rel: 'preconnect', href: 'https://try.abtasty.com', crossorigin: undefined },
    { rel: 'preconnect', href: 'https://cdn.cookielaw.org', crossorigin: undefined },
    { rel: 'preconnect', href: 'https://connect.facebook.net', crossorigin: undefined },
    { rel: 'preconnect', href: config.api.url, crossorigin: 'use-credentials' },
    { rel: 'preconnect', href: config.imageManager.default.protocol + '//' + config.imageManager.default.host, crossorigin: 'use-credentials' },
    { rel: 'dns-prefetch', href: config.imageManager.default.protocol + '//' + config.imageManager.default.host },
    { rel: 'preconnect', href: config.imageManager.product.protocol + '//' + config.imageManager.product.host, crossorigin: 'use-credentials' },
    { rel: 'dns-prefetch', href: config.imageManager.product.protocol + '//' + config.imageManager.product.host },
    { rel: 'preload', href: '/dist/prompt-v4-latin-300.woff2', as: 'font', type: 'font/woff2', crossorigin: undefined },
    { rel: 'preload', href: '/dist/prompt-v4-latin-500.woff2', as: 'font', type: 'font/woff2', crossorigin: undefined },
    { rel: 'preload', href: '/dist/prompt-v4-latin-600.woff2', as: 'font', type: 'font/woff2', crossorigin: undefined },
    { rel: 'preload', href: '/dist/prompt-v4-latin-regular.woff2', as: 'font', type: 'font/woff2', crossorigin: undefined }
    // { rel: 'preload', href: '/dist/zenith.woff2', as: 'font', type: 'font/woff2', crossorigin: undefined }
  ],
  script: [
    {
      src: 'https://cdn.jsdelivr.net/npm/pwacompat@2.0.17/pwacompat.min.js',
      async: true,
      integrity: 'sha256-QmifG9ty2co3761lBWJwL5KdDOdJ4sFjQ/ULE4aD18U=',
      crossorigin: 'anonymous'
    }
    // ,
    // {
    //   src: `https://maps.googleapis.com/maps/api/js?key=${config.google.places.key}&libraries=places`,
    //   async: true
    // }
    // {
    //   src: `https://static.cdn.prismic.io/prismic.js?repo=${config.prismic.repository}&new=true`,
    //   async: true
    // }
  ]
}
