<template>
  <div>
    <h2 class="title text-md-left">{{ $t('Stay in touch') }}</h2>
    <span class="lead -small text-center text-md-left">{{ $t('Subscribe newsletter') }}</span>
    <form class="form" @submit.prevent="subscribe" novalidate>
      <div class="form-line" :class="{'-error': $v.email.$error, '-top': (labelTop || email)}">
        <label for="newsletter" class="-small">{{ $t('Email address') }}</label>
        <input
          type="email"
          id="newsletter"
          @focus="labelTop = true"
          @blur="labelTop = false"
          v-model="email"
          required
        />
        <button type="submit" :aria-label="$t('Submit')">
          <i class="icon-next"></i>
        </button>
        <div
          class="form-error"
          :aria-hidden="$v.email.$error ? 'false' : 'true'"
        >{{ $t('Field is required.') }}</div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { ReadyHook } from 'vue-asyncready'
import { mapActions } from 'vuex'
export default {
  name: 'Newsletter',
  mixins: [ReadyHook],
  data() {
    return {
      email: '',
      labelTop: false
    }
  },
  validations: {
    email: {
      required,
      email
    }
  },
  methods: {
    ...mapActions('feedback', ['show']),
    async subscribe() {
      if (this.$v.$invalid) {
        this.$v.$touch()
      } else {
        await this.$store.dispatch('googleRecaptcha/isVerifiedCaptcha')
        if (!this.$store.state.googleRecaptcha.is_verified) {
          this.show({ message: 'Recaptcha error. Please try again.', context: 'error' })
        } else {
          const { url } = currentStoreView() || { url: '' }
          localStorage.setItem('email', this.email)
          this.$router.push({ path: url + '/newsletter' })
        }
      }
    }
  },
  mounted() {
    this.$v.$reset()
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

.form {
  margin-bottom: 4rem;
}

span {
  display: block;
  line-height: 1.58;
  margin-bottom: 2rem;

  &.lead {
    line-height: 1.36;
  }
}

h2.title,
h3.title,
h4.title {
  display: block;
  width: 100%;
  color: var(--zenith-footer-title);
  font-size: em(14);
  line-height: 1.36;
  letter-spacing: em(1);
  padding: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;
  background: none;
  border: none;
  font-weight: normal;

  @include bp(md) {
    pointer-events: none;
  }

  @include bp(max lg) {
    text-align: center;
  }
}

.text-md-left {
  @include bp(md) {
    text-align: left !important;
  }
}

.form-line {
  position: relative;
  padding: 0;
  margin: 0;

  label:not(.btn) {
    color: var(--zenith-footer-newsletter-label);
    font-style: italic;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 15;
    background: none;
    border: none;
    color: var(--zenith-footer-newsletter-button);
    height: 5rem;
    padding: 1.6rem 1.5rem 1.4rem;
    .icon-next {
      &:before {
        display: inline-block;
      }
    }
    &:hover, &:focus, &:active {
      color: var(--zenith-footer-newsletter-button-hover);
      .icon-next {
        &:before {
          @include pillNextHover();
        }
      }
    }
  }

  input {
    width: 100%;
    color: var(--zenith-footer-newsletter-input);
    background: var(--zenith-footer-newsletter-input-background);
    border-radius: 4px;
    padding: 1.8rem 2rem 0;
    border: solid 1px var(--zenith-footer-newsletter-input-border);
    appearance: none;
    -webkit-appearance: none;
    filter: none;
    transition: none;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: var(--zenith-white);
      -webkit-box-shadow: 0 0 0 1000px var(--zenith-black) inset;
    }
    &:focus, &:active {
      border-color: var(--zenith-footer-newsletter-input-border-focus);
    }
  }

  &.-top {
    label:not(.btn) {
      top: 0.4rem;
      color: var(--zenith-footer-newsletter-label-top);
      background: none;
    }
  }
  &.-error {
    input {
      border-color: var(--zenith-footer-newsletter-input-error);
    }
  }
}
</style>
