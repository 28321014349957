<template>
  <div>
  <ContactInfoSection classes="contact-footer" textalign="center" />
  <footer ref="footer" class="footer -visibility-auto" data-scroll-section>
    <h1 class="a11y-sr-only">Footer</h1>
    <div class="container">
      <div class="row" v-if="isContactEnabled">
        <div class="col-12 col-md-4">
          <Observer name="fade-up" @enter="enter">
            <Newsletter></Newsletter>
          </Observer>
        </div>
        <div class="col-12 col-md-7 offset-md-1 col-lg-6 offset-lg-2">
          <Observer name="fade-up" :class="'delay-0'">
            <h2 class="a11y-sr-only">{{ $t('Manufacture') }}</h2>
            <img src="../assets/img/tmp/footer.jpg" :alt="$t('Manufacture')" class="manufacture" width="220" height="132" loading="lazy">
            <strong aria-hidden="true" class="title text-md-left">{{ $t('Manufacture') }}</strong>
            <p class="-small text-center text-md-left">ZENITH Branch of LVMH Manufactures S.A.<br>34 rue des Billodes<br>2400 Le Locle, Switzerland</p>
            <router-link :to="localizedUrl + '/store-locator'" class="link-light -white">{{ $t('Find your store') }}</router-link>
          </Observer>
        </div>
      </div>
      <ul class="row list">
        <li class="col-12 col-md-4 col-lg-3">
          <Observer name="fade-up" :class="'delay-0'">
            <h2 class="a11y-sr-only">{{ $t('Collections') }}</h2>
            <strong aria-hidden="true" class="title d-none d-md-block">{{ $t('Collections') }}</strong>
            <button class="title" type="button" :aria-expanded="listActive === 1 ? true : false" aria-controls="footer-collections" @click="listActive = listActive === 1 ? 0 : 1" :class="{ '-active' : listActive === 1}">{{ $t('Collections') }}</button>
            <ul id="footer-collections">
              <li><router-link :to="localizedUrl + '/products/defy'" title="Defy">{{ $t('Defy') }}</router-link></li>
              <li><router-link :to="localizedUrl + '/products/chronomaster'" title="Chronomaster">{{ $t('Chronomaster') }}</router-link></li>
              <li><router-link :to="localizedUrl + '/products/pilot'" title="Pilot">{{ $t('Pilot') }}</router-link></li>
              <li><router-link :to="localizedUrl + '/products/elite'" title="Elite">{{ $t('Elite') }}</router-link></li>
              <li><router-link :to="localizedUrl + '/products/icons'" title="Elite">{{ $t('Icons') }}</router-link></li>
            </ul>
          </Observer>
        </li>
        <li class="col-12 col-md-4 col-lg-3">
          <Observer name="fade-up" :class="'delay-1'">
            <h2 class="a11y-sr-only">{{ $t('Zenith') }}</h2>
            <strong aria-hidden="true" class="title d-none d-md-block">{{ $t('Zenith') }}</strong>
            <button class="title" type="button" :aria-expanded="listActive === 2 ? true : false" aria-controls="footer-zenith" @click="listActive = listActive === 2 ? 0 : 2" :class="{ '-active' : listActive === 2}">{{ $t('Zenith') }}</button>
            <ul id="footer-zenith">
              <li><a href="http://pressroom.zenith-watches.com/" target="_blank" rel="noreferrer noopener">{{ $t('Press') }}</a></li>
              <li><router-link :to="localizedUrl + '/page/career'">{{ $t('Career') }}</router-link></li>
              <li><router-link :to="localizedUrl + '/brand/heritage'">{{ $t('Heritage') }}</router-link></li>
              <li><router-link :to="localizedUrl + '/brand/visit-the-manufacture'">{{ $t('Visit the manufacture') }}</router-link></li>
              <li><router-link :to="localizedUrl + '/brand/time-to-reach-your-star'">{{ $t('Time to reach your star') }}</router-link></li>
            </ul>
          </Observer>
        </li>
        <li class="col-12 col-md-4 col-lg-3">
          <Observer name="fade-up" :class="'delay-2'">
            <h2 class="a11y-sr-only">{{ $t('Services') }}</h2>
            <strong aria-hidden="true" class="title d-none d-md-block">{{ $t('Services') }}</strong>
            <button class="title" type="button" :aria-expanded="listActive === 3 ? true : false" aria-controls="footer-services" @click="listActive = listActive === 3 ? 0 : 3" :class="{ '-active' : listActive === 3}">{{ $t('Services') }}</button>
            <ul id="footer-services">
              <li v-for="(page, key) in getServicesLinks()">
                <router-link :to="localizedUrl + page.url">{{ page.label }}</router-link>
              </li>
            </ul>
          </Observer>
        </li>
        <li class="col-12 col-md-6 col-lg-3">
          <Observer name="fade-up" :class="'delay-3'">
            <h2 class="a11y-sr-only">{{ $t('Social') }}</h2>
            <strong aria-hidden="true" class="title">{{ $t('Social') }}</strong>
            <ul class="socials">
              <li><a href="https://instagram.com/zenithwatches/" target="_blank" rel="noreferrer noopener" @click="callGTM('Instagram')">
                <img src="../assets/icons/social/instagram.svg" :alt="$t('Instagram')" width="28px" height="28px" loading="lazy" ></a>
              </li>
              <li><a href="https://www.facebook.com/zenithwatches" target="_blank" rel="noreferrer noopener" @click="callGTM('Facebook')">
                <img src="../assets/icons/social/facebook.svg" :alt="$t('Facebook')" width="28px" height="28px" loading="lazy" ></a>
              </li>
              <li><a href="http://www.youtube.com/zenithwatches" target="_blank" rel="noreferrer noopener" @click="callGTM('Youtube')">
                <img src="../assets/icons/social/youtube.svg" :alt="$t('Youtube')" width="28px" height="28px" loading="lazy" ></a>
              </li>
              <li><a href="https://twitter.com/ZenithWatches" target="_blank" rel="noreferrer noopener" @click="callGTM('Twitter')">
                <img src="../assets/icons/social/x.svg" :alt="$t('Twitter')" width="28px" height="28px" loading="lazy" ></a>
              </li>
              <li><a href="http://www.linkedin.com/company/official-zenith-branch-of-lvmh-swiss-manufactures-sa" target="_blank" rel="noreferrer noopener" @click="callGTM('Linkedin')">
                <img src="../assets/icons/social/linkedin.svg" :alt="$t('Linkedin')" width="28px" height="28px" loading="lazy" ></a>
              </li>
              <li><a href="https://www.weibo.com/zenithchina" target="_blank" rel="noreferrer noopener" @click="callGTM('Weibo')">
                <img src="../assets/icons/social/weibo.svg" :alt="$t('Weibo')" width="28px" height="28px" loading="lazy" ></a>
              </li>
              <li>
                <button type="button" aria-controls="modal-wechat" :aria-expanded="tooltipWeChat ? 'true' : 'false'" @click="callWeChat">
                  <img src="../assets/icons/social/wechat.svg" :alt="$t('WeChat')" width="28px" height="28px" loading="lazy">
                </button>
                <a href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MjM5ODM5MDE5Mg==&subscene=0#wechat_redirect" target="_blank" rel="noreferrer noopener">
                  <img src="../assets/icons/social/wechat.svg" :alt="$t('WeChat')" width="28px" height="28px" loading="lazy" >
                </a>
              </li>
              <li><a href="https://lin.ee/XTWnnPA" target="_blank" rel="noreferrer noopener" @click="callGTM('Line')">
                <img src="../assets/icons/social/line.svg" :alt="$t('Line')" width="28px" height="28px" loading="lazy" ></a>
              </li>
              <li><a href="https://www.tiktok.com/@zenithwatches " target="_blank" rel="noreferrer noopener" @click="callGTM('TikTok')">
                <img src="../assets/icons/social/TikTok.png" :alt="$t('Tik tok')" width="28px" height="28px" loading="lazy" ></a>
              </li>
            </ul>
            <modal v-if="tooltipWeChat" id="wechat" :isOpen="tooltipWeChat" @close="() => { tooltipWeChat = false}">
              <div class="text-center">
                <img src="https://www.zenith-watches.com/assets/img/tmp/qrcode.jpg" :alt="$t('WeChat')" loading="lazy" >
              </div>
            </modal>
          </Observer>
        </li>
      </ul>
      <div class="row">
        <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-center">
          <Observer name="fade-drawn" class="obs-siege" rootMargin="0px">
            <router-link :to="localizedUrl" class="siege-link" :title="$t('Zenith Watches')">
              <svg aria-labelledby="title" ref="drawing" class="drawing" id="drawing" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 285.7 236.4" style="enable-background:new 0 0 285.7 236.4;" xml:space="preserve">
                <title id="title" :lang="localizedLang">{{ $t('Zenith Watches') }}</title>
                <line class="st0" x1="70.5" y1="214.1" x2="70.5" y2="171.6" />
                <rect x="70.5" y="171.6" class="st0" width="21.5" height="42.5" />
                <rect x="70.5" y="217.6" class="st0" width="21.5" height="9.8" />
                <line class="st0" x1="44.4" y1="214.1" x2="44.4" y2="171.6" />
                <line class="st0" x1="65.9" y1="214.1" x2="65.9" y2="171.6" />
                <rect x="44.4" y="171.6" class="st0" width="21.5" height="42.5" />
                <line class="st0" x1="39.8" y1="214.1" x2="39.8" y2="171.6" />
                <rect x="18.3" y="171.6" class="st0" width="21.5" height="42.5" />
                <rect x="44.3" y="217.6" class="st0" width="21.5" height="9.8" />
                <rect x="18.3" y="217.6" class="st0" width="21.5" height="9.8" />
                <line class="st0" x1="70.5" y1="148.8" x2="70.5" y2="106.2" />
                <rect x="70.5" y="106.2" class="st0" width="21.5" height="42.5" />
                <line class="st0" x1="44.4" y1="148.8" x2="44.4" y2="106.2" />
                <line class="st0" x1="65.9" y1="148.8" x2="65.9" y2="106.2" />
                <rect x="44.4" y="106.2" class="st0" width="21.5" height="42.5" />
                <line class="st0" x1="39.7" y1="148.8" x2="39.7" y2="106.2" />
                <rect x="18.2" y="106.2" class="st0" width="21.5" height="42.5" />
                <path class="st0" d="M18.2,79.9" />
                <polyline class="st0" points="95.7,86.9 95.7,88.1 14.4,88.1 14.4,86.9 " />
                <line class="st0" x1="14.4" y1="101.4" x2="95.7" y2="101.4" />
                <line class="st0" x1="14.4" y1="153.6" x2="95.7" y2="153.6" />
                <line class="st0" x1="102.7" y1="153.6" x2="183.9" y2="153.6" />
                <line class="st0" x1="272.2" y1="153.6" x2="190.9" y2="153.6" />
                <path class="st0" d="M14.4,100.7" />
                <path class="st0" d="M95.7,100.7" />
                <polyline class="st0" points="14.4,168 14.4,166.8 95.7,166.8 95.7,168 " />
                <rect x="7.4" y="230.8" class="st0" width="271.9" height="4.5" />
                <polyline class="st0" points="284.7,18.6 284.7,24.6 1,24.6 1,17.4 " />
                <line class="st0" x1="158.8" y1="214.1" x2="158.8" y2="171.6" />
                <line class="st0" x1="180.3" y1="214.1" x2="180.3" y2="172.4" />
                <rect x="158.8" y="171.6" class="st0" width="21.5" height="42.5" />
                <rect x="158.8" y="217.6" class="st0" width="21.5" height="9.8" />
                <line class="st0" x1="132.7" y1="214.1" x2="132.7" y2="171.6" />
                <line class="st0" x1="154.2" y1="214.1" x2="154.2" y2="171.6" />
                <rect x="132.7" y="171.6" class="st0" width="21.5" height="42.5" />
                <line class="st0" x1="106.6" y1="214.1" x2="106.6" y2="172.4" />
                <line class="st0" x1="128.1" y1="214.1" x2="128.1" y2="171.6" />
                <rect x="106.6" y="171.6" class="st0" width="21.5" height="42.5" />
                <rect x="132.7" y="217.6" class="st0" width="21.5" height="9.8" />
                <rect x="106.6" y="217.6" class="st0" width="21.8" height="9.8" />
                <line class="st0" x1="158.8" y1="148.8" x2="158.8" y2="106.2" />
                <rect x="158.8" y="106.2" class="st0" width="21.5" height="42.5" />
                <line class="st0" x1="132.7" y1="148.8" x2="132.7" y2="106.2" />
                <line class="st0" x1="154.2" y1="148.8" x2="154.2" y2="106.2" />
                <rect x="132.7" y="106.2" class="st0" width="21.5" height="42.5" />
                <line class="st0" x1="127.9" y1="148.8" x2="127.9" y2="106.2" />
                <rect x="106.5" y="106.2" class="st0" width="21.5" height="42.5" />
                <line class="st0" x1="184" y1="88.2" x2="102.7" y2="88.2" />
                <polyline class="st0" points="102.7,102.6 102.7,101.4 184,101.4 " />
                <line class="st0" x1="102.7" y1="100.7" x2="102.7" y2="99.5" />
                <path class="st0" d="M184,100.7" />
                <path class="st0" d="M184,99.5" />
                <polyline class="st0" points="102.7,168 102.7,166.8 184,166.8 " />
                <line class="st0" x1="247.1" y1="214.1" x2="247.1" y2="171.6" />
                <rect x="247.1" y="171.6" class="st0" width="21.5" height="42.5" />
                <rect x="247.1" y="217.6" class="st0" width="21.5" height="9.8" />
                <line class="st0" x1="221" y1="214.1" x2="221" y2="171.6" />
                <line class="st0" x1="242.5" y1="214.1" x2="242.5" y2="171.6" />
                <rect x="221" y="171.6" class="st0" width="21.5" height="42.5" />
                <line class="st0" x1="194.9" y1="214.1" x2="194.9" y2="172.4" />
                <line class="st0" x1="216.4" y1="214.1" x2="216.4" y2="171.6" />
                <rect x="194.9" y="171.6" class="st0" width="21.5" height="42.5" />
                <rect x="221" y="217.6" class="st0" width="21.5" height="9.8" />
                <rect x="194.9" y="217.6" class="st0" width="21.5" height="9.8" />
                <line class="st0" x1="247.1" y1="148.8" x2="247.1" y2="106.2" />
                <rect x="247.1" y="106.2" class="st0" width="21.5" height="42.5" />
                <line class="st0" x1="221" y1="148.8" x2="221" y2="106.2" />
                <line class="st0" x1="242.5" y1="148.8" x2="242.5" y2="106.2" />
                <rect x="221" y="106.2" class="st0" width="21.5" height="42.5" />
                <line class="st0" x1="216.2" y1="148.8" x2="216.2" y2="106.2" />
                <rect x="194.7" y="106.2" class="st0" width="21.5" height="42.5" />
                <line class="st0" x1="272.2" y1="88.2" x2="191" y2="88.2" />
                <polyline class="st0" points="191,101.4 272.2,101.4 272.2,102.6 " />
                <line class="st0" x1="272.2" y1="99.5" x2="272.2" y2="100.7" />
                <line class="st0" x1="191" y1="166.8" x2="272.2" y2="166.8" />
                <polygon class="st0" points="284.7,16.1 284.7,17.4 1,17.4 1,16.1 23.5,1 24.3,1 263.1,1.3 " />
                <line class="st0" x1="284.7" y1="17.4" x2="284.7" y2="18.6" />
                <line class="st0" x1="272.2" y1="28.2" x2="272.2" y2="230.8" />
                <line class="st0" x1="190.9" y1="28.2" x2="190.9" y2="230.8" />
                <line class="st0" x1="183.9" y1="28.2" x2="183.9" y2="230.8" />
                <line class="st0" x1="102.7" y1="28.2" x2="102.7" y2="230.8" />
                <line class="st0" x1="95.7" y1="28.2" x2="95.7" y2="230.8" />
                <line class="st0" x1="14.4" y1="28.2" x2="14.4" y2="230.8" />
                <polyline class="st0" points="7.4,230.4 7.4,28.1 279.3,28.2 279.3,230.1 279.3,230.8 " />
                <line class="st0" x1="7.4" y1="230.1" x2="7.4" y2="230.8" />
                <path class="st0" d="M39.6,39c0-0.2-0.1-0.3-0.1-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.2-0.1-0.3-0.2-0.5c-0.2-0.4-0.4-0.7-0.6-1
	c-0.1-0.2-0.2-0.4-0.4-0.5c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.3-0.5-0.5-0.8-0.8c-0.1-0.1-0.2-0.2-0.3-0.3
	c-0.2-0.2-0.5-0.4-0.8-0.6h0c-0.2-0.2-0.5-0.3-0.8-0.5c-0.2-0.1-0.5-0.3-0.7-0.4c0,0-0.1,0-0.1,0c-0.5-0.2-1.1-0.4-1.6-0.6
	c-0.6-0.2-1.2-0.3-1.8-0.4c-0.6-0.1-1.3-0.1-1.9-0.1h0c-0.5,0-1,0-1.6,0.1c-0.8,0.1-1.5,0.2-2.2,0.4c-0.5,0.2-1.1,0.4-1.6,0.6
	c-0.3,0.1-0.6,0.3-0.9,0.4c-0.2,0.1-0.5,0.3-0.7,0.4l0,0c-0.3,0.2-0.6,0.4-0.9,0.6c-0.1,0.1-0.2,0.1-0.3,0.2
	c-0.3,0.2-0.6,0.5-0.8,0.8c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2,0.3-0.4,0.7-0.6,1c-0.1,0.1-0.1,0.3-0.2,0.4
	c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.3-0.1,0.4v0l0,42.5h4.4h4.3h4.3h4.3h4.3V39.5l0,0c0-0.1,0-0.1,0-0.2
	L39.6,39z" />
                <path class="st0" d="M44.5,79.9" />
                <path class="st0" d="M65.9,39c0-0.2-0.1-0.3-0.1-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.2-0.1-0.3-0.2-0.5c-0.2-0.4-0.4-0.7-0.6-1
	c-0.1-0.2-0.2-0.4-0.4-0.5c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.3-0.5-0.5-0.8-0.8c-0.1-0.1-0.2-0.2-0.3-0.3
	c-0.2-0.2-0.5-0.4-0.8-0.6h0c-0.2-0.2-0.5-0.3-0.8-0.5c-0.2-0.1-0.5-0.3-0.7-0.4c0,0-0.1,0-0.1,0c-0.5-0.2-1.1-0.4-1.6-0.6
	c-0.6-0.2-1.2-0.3-1.8-0.4c-0.6-0.1-1.3-0.1-1.9-0.1h0c-0.5,0-1,0-1.6,0.1c-0.8,0.1-1.5,0.2-2.2,0.4c-0.5,0.2-1.1,0.4-1.6,0.6
	c-0.3,0.1-0.6,0.3-0.9,0.4c-0.2,0.1-0.5,0.3-0.7,0.4l0,0c-0.3,0.2-0.6,0.4-0.9,0.6c-0.1,0.1-0.2,0.1-0.3,0.2
	c-0.3,0.2-0.6,0.5-0.8,0.8c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2,0.3-0.4,0.7-0.6,1c-0.1,0.1-0.1,0.3-0.2,0.4
	c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.3-0.1,0.4v0l0,42.5h4.4h4.3h4.3h4.3H66V39.5l0,0c0-0.1,0-0.1,0-0.2
	L65.9,39z" />
                <path class="st0" d="M70.8,79.9" />
                <path class="st0" d="M92.2,39c0-0.2-0.1-0.3-0.1-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.2-0.1-0.3-0.2-0.5c-0.2-0.4-0.4-0.7-0.6-1
	c-0.1-0.2-0.2-0.4-0.4-0.5c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.3-0.5-0.5-0.8-0.8c-0.1-0.1-0.2-0.2-0.3-0.3
	c-0.2-0.2-0.5-0.4-0.8-0.6h0c-0.2-0.2-0.5-0.3-0.8-0.5c-0.2-0.1-0.5-0.3-0.7-0.4c0,0-0.1,0-0.1,0c-0.5-0.2-1.1-0.4-1.6-0.6
	c-0.6-0.2-1.2-0.3-1.8-0.4c-0.6-0.1-1.3-0.1-1.9-0.1h0c-0.5,0-1,0-1.6,0.1c-0.8,0.1-1.5,0.2-2.2,0.4c-0.5,0.2-1.1,0.4-1.6,0.6
	c-0.3,0.1-0.6,0.3-0.9,0.4c-0.2,0.1-0.5,0.3-0.7,0.4l0,0c-0.3,0.2-0.6,0.4-0.9,0.6c-0.1,0.1-0.2,0.1-0.3,0.2
	c-0.3,0.2-0.6,0.5-0.8,0.8c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2,0.3-0.4,0.7-0.6,1c-0.1,0.1-0.1,0.3-0.2,0.4
	c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.3-0.1,0.4v0l0,42.5h4.4h4.3h4.3H88h4.3V39.5l0,0c0-0.1,0-0.1,0-0.2
	L92.2,39z" />
                <path class="st0" d="M106.5,79.9" />
                <path class="st0" d="M127.9,39c0-0.2-0.1-0.3-0.1-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.2-0.1-0.3-0.2-0.5c-0.2-0.4-0.4-0.7-0.6-1
	c-0.1-0.2-0.2-0.4-0.4-0.5c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.3-0.5-0.5-0.8-0.8c-0.1-0.1-0.2-0.2-0.3-0.3
	c-0.2-0.2-0.5-0.4-0.8-0.6h0c-0.2-0.2-0.5-0.3-0.8-0.5c-0.2-0.1-0.5-0.3-0.7-0.4c0,0-0.1,0-0.1,0c-0.5-0.2-1.1-0.4-1.6-0.6
	c-0.6-0.2-1.2-0.3-1.8-0.4c-0.6-0.1-1.3-0.1-1.9-0.1h0c-0.5,0-1,0-1.6,0.1c-0.8,0.1-1.5,0.2-2.2,0.4c-0.5,0.2-1.1,0.4-1.6,0.6
	c-0.3,0.1-0.6,0.3-0.9,0.4c-0.2,0.1-0.5,0.3-0.7,0.4l0,0c-0.3,0.2-0.6,0.4-0.9,0.6c-0.1,0.1-0.2,0.1-0.3,0.2
	c-0.3,0.2-0.6,0.5-0.8,0.8c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2,0.3-0.4,0.7-0.6,1c-0.1,0.1-0.1,0.3-0.2,0.4
	c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.3-0.1,0.4v0l0,42.5h4.4h4.3h4.3h4.3h4.3V39.5l0,0c0-0.1,0-0.1,0-0.2
	L127.9,39z" />
                <path class="st0" d="M132.8,79.9" />
                <path class="st0" d="M154.1,39c0-0.2-0.1-0.3-0.1-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.2-0.1-0.3-0.2-0.5c-0.2-0.4-0.4-0.7-0.6-1
	c-0.1-0.2-0.2-0.4-0.4-0.5c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.3-0.5-0.5-0.8-0.8c-0.1-0.1-0.2-0.2-0.3-0.3
	c-0.2-0.2-0.5-0.4-0.8-0.6h0c-0.2-0.2-0.5-0.3-0.8-0.5c-0.2-0.1-0.5-0.3-0.7-0.4c0,0-0.1,0-0.1,0c-0.5-0.2-1.1-0.4-1.6-0.6
	c-0.6-0.2-1.2-0.3-1.8-0.4c-0.6-0.1-1.3-0.1-1.9-0.1h0c-0.5,0-1,0-1.6,0.1c-0.8,0.1-1.5,0.2-2.2,0.4c-0.5,0.2-1.1,0.4-1.6,0.6
	c-0.3,0.1-0.6,0.3-0.9,0.4c-0.2,0.1-0.5,0.3-0.7,0.4l0,0c-0.3,0.2-0.6,0.4-0.9,0.6c-0.1,0.1-0.2,0.1-0.3,0.2
	c-0.3,0.2-0.6,0.5-0.8,0.8c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2,0.3-0.4,0.7-0.6,1c-0.1,0.1-0.1,0.3-0.2,0.4
	c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.3-0.1,0.4v0l0,42.5h4.4h4.3h4.3h4.3h4.3V39.5l0,0c0-0.1,0-0.1,0-0.2
	L154.1,39z" />
                <path class="st0" d="M159,79.9" />
                <path class="st0" d="M180.4,39c0-0.2-0.1-0.3-0.1-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.2-0.1-0.3-0.2-0.5c-0.2-0.4-0.4-0.7-0.6-1
	c-0.1-0.2-0.2-0.4-0.4-0.5c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.3-0.5-0.5-0.8-0.8c-0.1-0.1-0.2-0.2-0.3-0.3
	c-0.2-0.2-0.5-0.4-0.8-0.6h0c-0.2-0.2-0.5-0.3-0.8-0.5c-0.2-0.1-0.5-0.3-0.7-0.4c0,0-0.1,0-0.1,0c-0.5-0.2-1.1-0.4-1.6-0.6
	c-0.6-0.2-1.2-0.3-1.8-0.4c-0.6-0.1-1.3-0.1-1.9-0.1h0c-0.5,0-1,0-1.6,0.1c-0.8,0.1-1.5,0.2-2.2,0.4c-0.5,0.2-1.1,0.4-1.6,0.6
	c-0.3,0.1-0.6,0.3-0.9,0.4c-0.2,0.1-0.5,0.3-0.7,0.4l0,0c-0.3,0.2-0.6,0.4-0.9,0.6c-0.1,0.1-0.2,0.1-0.3,0.2
	c-0.3,0.2-0.6,0.5-0.8,0.8c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2,0.3-0.4,0.7-0.6,1c-0.1,0.1-0.1,0.3-0.2,0.4
	c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.3-0.1,0.4v0l0,42.5h4.4h4.3h4.3h4.3h4.3V39.5l0,0c0-0.1,0-0.1,0-0.2
	L180.4,39z" />
                <path class="st0" d="M194.7,79.9" />
                <path class="st0" d="M216.1,39c0-0.2-0.1-0.3-0.1-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.2-0.1-0.3-0.2-0.5c-0.2-0.4-0.4-0.7-0.6-1
	c-0.1-0.2-0.2-0.4-0.4-0.5c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.3-0.5-0.5-0.8-0.8c-0.1-0.1-0.2-0.2-0.3-0.3
	c-0.2-0.2-0.5-0.4-0.8-0.6h0c-0.2-0.2-0.5-0.3-0.8-0.5c-0.2-0.1-0.5-0.3-0.7-0.4c0,0-0.1,0-0.1,0c-0.5-0.2-1.1-0.4-1.6-0.6
	c-0.6-0.2-1.2-0.3-1.8-0.4c-0.6-0.1-1.3-0.1-1.9-0.1h0c-0.5,0-1,0-1.6,0.1c-0.8,0.1-1.5,0.2-2.2,0.4c-0.5,0.2-1.1,0.4-1.6,0.6
	c-0.3,0.1-0.6,0.3-0.9,0.4c-0.2,0.1-0.5,0.3-0.7,0.4l0,0c-0.3,0.2-0.6,0.4-0.9,0.6c-0.1,0.1-0.2,0.1-0.3,0.2
	c-0.3,0.2-0.6,0.5-0.8,0.8c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2,0.3-0.4,0.7-0.6,1c-0.1,0.1-0.1,0.3-0.2,0.4
	c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.3-0.1,0.4v0l0,42.5h4.4h4.3h4.3h4.3h4.3V39.5l0,0c0-0.1,0-0.1,0-0.2
	L216.1,39z" />
                <path class="st0" d="M221,79.9" />
                <path class="st0" d="M242.4,39c0-0.2-0.1-0.3-0.1-0.5c0-0.2-0.1-0.4-0.2-0.5s-0.1-0.3-0.2-0.5c-0.2-0.4-0.3-0.7-0.6-1
	c-0.1-0.2-0.2-0.4-0.4-0.5c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.3-0.5-0.5-0.8-0.8c-0.1-0.1-0.2-0.2-0.3-0.3
	c-0.2-0.2-0.5-0.4-0.8-0.6h0c-0.2-0.2-0.5-0.3-0.8-0.5c-0.2-0.1-0.5-0.3-0.7-0.4c0,0-0.1,0-0.1,0c-0.5-0.2-1.1-0.4-1.6-0.6
	c-0.6-0.2-1.2-0.3-1.8-0.4c-0.6-0.1-1.3-0.1-1.9-0.1h0c-0.5,0-1,0-1.6,0.1c-0.8,0.1-1.5,0.2-2.2,0.4c-0.5,0.2-1.1,0.4-1.6,0.6
	c-0.3,0.1-0.6,0.3-0.9,0.4c-0.2,0.1-0.5,0.3-0.7,0.4l0,0c-0.3,0.2-0.6,0.4-0.9,0.6c-0.1,0.1-0.2,0.1-0.3,0.2
	c-0.3,0.2-0.6,0.5-0.8,0.8c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2,0.3-0.4,0.7-0.6,1c-0.1,0.1-0.1,0.3-0.2,0.4
	c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.3-0.1,0.4v0l0,42.5h4.4h4.3h4.3h4.3h4.3V39.5l0,0c0-0.1,0-0.1,0-0.2
	L242.4,39z" />
                <path class="st0" d="M247.3,79.9" />
                <path class="st0" d="M268.7,39c0-0.2-0.1-0.3-0.1-0.5c0-0.2-0.1-0.4-0.2-0.5s-0.1-0.3-0.2-0.5c-0.2-0.4-0.3-0.7-0.6-1
	c-0.1-0.2-0.2-0.4-0.4-0.5c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.3-0.5-0.5-0.8-0.8c-0.1-0.1-0.2-0.2-0.3-0.3
	c-0.2-0.2-0.5-0.4-0.8-0.6h0c-0.2-0.2-0.5-0.3-0.8-0.5c-0.2-0.1-0.5-0.3-0.7-0.4c0,0-0.1,0-0.1,0c-0.5-0.2-1.1-0.4-1.6-0.6
	c-0.6-0.2-1.2-0.3-1.8-0.4c-0.6-0.1-1.3-0.1-1.9-0.1h0c-0.5,0-1,0-1.6,0.1c-0.8,0.1-1.5,0.2-2.2,0.4c-0.5,0.2-1.1,0.4-1.6,0.6
	c-0.3,0.1-0.6,0.3-0.9,0.4c-0.2,0.1-0.5,0.3-0.7,0.4l0,0c-0.3,0.2-0.6,0.4-0.8,0.6c-0.1,0.1-0.2,0.1-0.3,0.2
	c-0.3,0.2-0.6,0.5-0.8,0.8c-0.1,0.1-0.2,0.3-0.3,0.4c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2,0.3-0.4,0.7-0.6,1c-0.1,0.1-0.1,0.3-0.2,0.4
	c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.3-0.1,0.4v0l0,42.5h4.4h4.3h4.3h4.3h4.3V39.5l0,0c0-0.1,0-0.1,0-0.2
	L268.7,39z" />
                <path class="st0" d="M44.7,92.2" />
                <path class="st0" d="M106.2,79.9" />
                <path class="st0" d="M132.8,85.6" />
                <path class="st0" d="M194.6,79.9" />
                <path class="st0" d="M221.2,85.6" />
                <rect x="40.1" y="88.2" class="st0" width="4.5" height="13.2" />
                <rect x="66.3" y="88.2" class="st0" width="4.5" height="13.2" />
                <path class="st0" d="M133.2,92.2" />
                <rect x="128.4" y="88.2" class="st0" width="4.5" height="13.2" />
                <rect x="154.6" y="88.2" class="st0" width="4.5" height="13.2" />
                <path class="st0" d="M221.7,92.2" />
                <rect x="216.4" y="88.2" class="st0" width="4.5" height="13.2" />
                <rect x="243.2" y="88.2" class="st0" width="4.5" height="13.2" />
                <path class="st0" d="M45.4,156.8" />
                <rect x="40.1" y="153.6" class="st0" width="4.5" height="13.2" />
                <rect x="66.3" y="153.6" class="st0" width="4.5" height="13.2" />
                <path class="st0" d="M133,157.6" />
                <rect x="128.2" y="153.6" class="st0" width="4.5" height="13.2" />
                <rect x="154.6" y="153.6" class="st0" width="4.5" height="13.2" />
                <path class="st0" d="M221.7,157.6" />
                <rect x="216.4" y="153.6" class="st0" width="4.5" height="13.2" />
                <rect x="243" y="153.6" class="st0" width="4.5" height="13.2" />
                <path class="st0" d="M7.4,24.6" />
                <line class="st0" x1="279.3" y1="28.2" x2="279.3" y2="24.6" />
                <line class="st0" x1="7.4" y1="28.1" x2="7.4" y2="24.6" />
              </svg>
            </router-link>
            <p class="-small">{{ $t('The future of swiss watchmaking since 1865') }}</p>
          </Observer>
        </div>
      </div>
      <div class="row links">
        <div class="col-12 col-lg">
          <Observer name="fade-up" :class="'delay-0'" rootMargin="0px">
            <ul>
              <li><router-link :to="localizedUrl + '/page/privacy-policy'">{{ $t('Privacy') }}</router-link></li>
              <li><router-link :to="localizedUrl + '/page/terms'">{{ $t('Terms and conditions') }}</router-link></li>
              <li v-if="ecommerceActivated"><router-link :to="localizedUrl + '/page/sales-condition'">{{ $t('Sales condition') }}</router-link></li>
              <li><router-link :to="localizedUrl + '/page/responsible-business-practices-policy'">{{ $t('Responsible practices') }}</router-link></li>
              <li><a href="http://pressroom.zenith-watches.com/" target="_blank" rel="noreferrer noopener">{{ $t('Press') }}</a></li>
              <li><router-link :to="localizedUrl + '/page/career'">{{ $t('Career') }}</router-link></li>
              <li><router-link :to="localizedUrl + '/sitemap'">{{ $t('Sitemap') }}</router-link></li>
            </ul>
          </Observer>
        </div>
        <div class="col-12 col-lg -upper">
          <Observer name="fade-up" :class="'delay-1'" rootMargin="0px">
            <span v-html="$t('Made with Zenith at Le Locle © ZENITH 2020')"></span>
          </Observer>
        </div>
      </div>
      <div class="hk-footnote" v-if="showHkFootnote">
        <a :href="hkFootnotePdf" target="_blank">Dealers in Precious Metals and Stones</a>
        <a :href="hkFootnotePdf" target="_blank">Category A Registration No.: A-B-23-12-03809</a>
      </div>
    </div>
  </footer>
</div>
</template>

<script>
import Newsletter from './Newsletter'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { ReadyHook } from 'vue-asyncready'
import { DISABLED_URLS_STORES } from '../utilities'
import ContactInfoSection from './ContactInfoSection.vue'
// import Modal from '../components/utils/Modal'

export default {
  name: 'Footer',
  mixins: [ReadyHook],
  components: {
    Newsletter,
    Modal: () => import(/* webpackChunkName: "modal-cpt" */ '../components/utils/Modal'),
    ContactInfoSection,
  },
  data () {
    return {
      appeared: false,
      listActive: 0,
      tooltipWeChat: false,
      ecommerceActivated: null
    }
  },
  computed: {
    localizedUrl () {
      return currentStoreView().url || ''
    },
    isContactEnabled() {
      const { contact } = currentStoreView()
      return contact !== false
    },
    localizedLang() {
      return currentStoreView().i18n.defaultLanguage.toLowerCase()
    },
    showHkFootnote() {
      const { i18n: { defaultCountry, defaultLanguage } } = currentStoreView()
      return defaultCountry.toLowerCase() === 'hk' && defaultLanguage.toLowerCase() === 'en'
    },
    hkFootnotePdf() {
      return `/assets/pdf/Registration_no._A-B-23-12-03809).pdf`
    }
  },
  created() {
      this.ecommerceActivated = this.$store.state['zenith-ui'].ecommerce;
  },
  methods: {
    enter() {
      if (!this.appeared) {
        this.appeared = true
        this.$refs.footer.classList.remove('-visibility-auto')
      }
    },
    callWeChat (network) {
      this.tooltipWeChat = true
      this.callGTM('WeChat')
    },
    callGTM (network) {
      this.$gtm.trackEvent({
        event: 'socialFollow',
        socialAction: 'Follow',
        socialNetwork: network
      })
    },
    getServicesLinks() {
      const storeCode = currentStoreView().storeCode
      const pages = [{
        url: '/service/maintenance-and-services',
        label: this.$t('Maintenance & services')
      }, {
        url: '/service/return-your-watch-to-manufacture',
        label: this.$t('Return your watch')
      }, {
        url: '/service/vintage-watch-restoration',
        label: this.$t('Vintage watch restoration')
      }, {
        url: '/service/extracts-certificates',
        label: this.$t('Extracts and certificates')
      }, {
        url: '/faq',
        label: this.$t('FAQ')
      }, {
        url: '/service/catalogue',
        label: this.$t('Catalogue')
      }]
      if (!DISABLED_URLS_STORES.hasOwnProperty(storeCode)) {
        return pages
      }
      return pages.filter(page => !DISABLED_URLS_STORES[storeCode].includes(page.url))
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/core";

.footer {
  position: relative;
  color: var(--zenith-footer-text);
  background: var(--zenith-footer-background);
  padding: 6rem 0 4rem;
  overflow: hidden;
  border-top: 1px solid var(--zenith-footer-border-top);

  p {
    line-height: 1.58;
    margin-bottom: 2rem;

    &.lead {
      line-height: 1.36;
    }
  }

  .row:not(:first-child) {
    position: relative;
    padding-top: 2rem;

    &:before {
      display: block;
      content: '';
      position: absolute;
      top: 2rem;
      left: 50%;
      width: 100vw;
      height: .1rem;
      background: #4C4C4C;
      transform: translateX(-50%);
    }
  }

  .manufacture {
    float: left;
    width: 22rem;
    margin-right: 2rem;

    @include bp(max md) {
      width: 100%;
      margin-bottom: 2rem;
      margin-right: 0;
    }
  }

  .text-md-left {
    @include bp(md) {
      text-align: left !important;
    }
  }

  .col-12.col-md-7 {
    @include bp(max md) {
      text-align: center;
    }
  }
  .link-light {
    @include bp(max md) {
      margin: 0 auto 4rem;
    }
  }

  .title {
    display: block;
    width: 100%;
    color: var(--zenith-footer-title);
    text-align: left;
    line-height: 1.36;
    letter-spacing: em(1);
    font-size: em(14);
    padding: 0;
    margin-bottom: 1rem;
    text-transform: uppercase;
    background: none;
    border: none;
    font-weight: normal;

    @include bp(md) {
      pointer-events: none;
    }

    @include bp(max lg) {
      text-align: center;
    }
  }

  button.title {
    display: none;

    @include bp(max md) {
      display: block;
      position: relative;
      color: var(--zenith-component-title);
      text-align: left;
      font-size: em(12);
      line-height: 1.58;
      letter-spacing: em(.2, 12);
      margin: 0 -1.5rem -.1rem;
      width: calc(100% + 3rem);
      border-top: 1px solid var(--zenith-border);
      padding: 1.7rem 1.5rem 1.3rem;
      border-bottom: 1px solid var(--zenith-border);

      &:after {
        position: absolute;
        right: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
        content: "\e928";
        font-family: 'zenith', sans-serif !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        font-size: em(20);
        transition: transform 0.2s ease-in-out;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      & + ul {
        margin: 0;
        padding: 0;
        text-align: left;
        overflow: hidden;
        max-height: 0;
        transition: all 0.2s ease-in-out;

        li a {
          line-height: em(30, 14);
        }
      }
      &.-active {
        &:after {
          transform: translateY(-50%) rotate(45deg);
        }
        & + ul {
          padding: 2rem 0;
          max-height: 20rem;
        }
      }
    }
  }

  .text-center .title {
    text-align: center;
  }

  .obs-siege {
    display: inline-block;
    a {
      display: block;
      .drawing {
        max-width: 15rem;
        margin-top: 6rem;
        margin-bottom: 1.8rem;
        background-color: var(--zenith-footer-background);
        .st0 {
          fill: none;
          stroke: #FFFFFF;
          stroke-width: 2;
          stroke-miterlimit: 10;
          stroke-dasharray: 1000;
          stroke-dashoffset: 1000;
          animation: dash 4s cubic-bezier(0.390, 0.575, 0.565, 1.000) forwards;
        }
      }
      & + p {
        text-transform: uppercase;
      }
      // &:hover {
        // .drawing {
          // .st0 {
            // animation: dash-over 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) forwards;
          // }
        // }
      // }
    }

    &.obs-fade-drawn{
      a {
        .drawing {
          .st0 {
            animation: none;
          }
        }
      }
    }
  }

  ul {
    padding: 0;
    list-style: none;
    margin-bottom: 4rem;

    li {

      a:not(.btn) {
        color: var(--zenith-footer-text-link);
        display: block;
        font-size: em(12);
        line-height: em(24, 12);
        letter-spacing: em(.2, 12);

        &:hover, &:focus, &:active {
          color: var(--zenith-grey);
        }
      }
    }
  }

  .row.list {
    margin-bottom: 3rem;
    padding-top: 6rem;

    &:first-child {
      padding-top: 0;
    }

    @include bp(max lg) {
      text-align: center;
    }
    @include bp(max md) {
      padding-top: 0;

      &:before {
        display: none;
      }
    }

    .btn {
      margin-bottom: 2rem;
      @include bp(max md) {
        margin-bottom: 0;
      }
    }

    strong.title,
    h2.title,
    h3.title,
    h4.title {
      @include bp(max md) {
        margin-top: 4rem;
        margin-bottom: 1.2rem;
      }
    }

    .socials {
      margin: 0 -.6rem 3rem;

      li {
        width: 30%;
        display: inline-block;
        vertical-align: top;

        a, button {
          background: none;
          border: none;
          padding: 0 1rem 1rem;

          img {
            display: block;
            margin: 0;
            max-width: none;
            height: 2.8rem;
            transition: all 0.2s ease-in-out;

            @include bp(max md) {
              margin: auto;
            }
          }

          &:hover, &:active, &:focus {
            img {
              opacity: 0.6;
              transform: scale(1.2);
            }
          }
        }

        button {
          @include bp(max md) {
            display: none;
          }
          & + a {
            display: none;
            @include bp(max md) {
              display: block;
            }
          }
        }
      }
    }
  }

  .row.links {
    margin-top: 2rem;
    padding-top: 6rem;

    ul {
      white-space: nowrap;

      @include bp(max md) {
        white-space: normal;
      }

      li {
        display: inline-block;
        margin-right: 2rem;

        a {
          text-transform: uppercase;
        }
      }
    }

    .col-12 {
      &:last-child {
        text-align: right;
        font-size: em(10);
        line-height: em(22, 10);
        letter-spacing: em(.17, 10);

        @include bp(max lg) {
          text-align: center;
        }

        /deep/ i {
          font-size: em(14, 10);
          display: inline-block;
          top: .2rem;
          position: relative;
        }

        a {
          color: var(--zenith-footer-text-link);
          display: inline-block;

          &:hover, &:focus, &:active {
            color: var(--zenith-grey);
          }
          &:last-child {
            margin-left: 2rem;
          }
        }
      }

      @include bp(max lg) {
        text-align: center;
      }
    }

  }
}

.hk-footnote {
  display: flex;
  flex-flow: column;
  text-align: center;
  font-size: em(12);
  line-height: em(24, 12);
  margin-top: 12px;

  a {
    display: inline-flex;
    margin: auto;
    color: inherit;
  }
}
</style>
